/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages


                // Navigation
                var navHeader = $('.header');
                var navMenu = $('.header__content');
                var navMenuNav = $('.header__nav li');
                var navMobile = $('.header__mobile');
                var navToggle = $('.nav__toggle');

                if (true === navMenuNav.hasClass('current-menu-ancestor')) {
                    navMenu.addClass('current-sub');
                }

                navToggle.click(
                    function () {
                        navMobile.toggle('slow');
                    }
                );

                // Navigation after scroll
                /*
                var navAnchor = $('#main').first().offset().top + 200;
                console.log(navAnchor);

                $(window).on('scroll', function() {
                    var wst = $(window).scrollTop();
                    if( wst >= navAnchor ){
                        navHeader.addClass('after-scroll');
                    } else {
                        navHeader.removeClass('after-scroll');
                    }

                });*/


                // WOW + Animate.css
                // Add class to elment

                // WOW + Animate.css
                // setting
                /*var wow = new WOW({
                    //offset: 200,
                    animateClass: 'animate__animated',
                    scrollContainer: 'body'
                });

                // init
                wow.init();*/
                /*var scrolled = false;
                $(window).on('scroll', function() {
                    if (!scrolled) {
                        scrolled = true;
                        wow.init();
                    }
                });*/


                // Magnific Popup
                // Image
                var magPopImg = $('.magnificpopup');
                magPopImg.magnificPopup({
                    type: 'image',
                    image: {
                        titleSrc:
                            function (item) {
                                return item.el.find('img').attr('alt');
                            }
                    }
                });

                // Video
                var magPopVideo = $('.magnificpopup--video');
                magPopVideo.magnificPopup({
                    type: 'iframe',
                    iframe: {
                        markup:
                            '<div class="mfp-iframe-scaler">' +
                            '<div class="mfp-close"></div>' +
                            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
                            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
                        patterns: {
                            youtube: {
                                index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

                                id: 'v=', // String that splits URL in a two parts, second part should be %id%
                                // Or null - full URL will be returned
                                // Or a function that should return %id%, for example:
                                // id: function(url) { return 'parsed id'; }

                                src: '//www.youtube.com/embed/%id%' // URL that will be set as a source for iframe.
                            },
                        },
                    }
                });

                // Gallery
                var magPopGallery = $('.magnificpopup--gallery');
                magPopGallery.each(function () { // the containers for all your galleries

                    $(this).find('a').magnificPopup({
                        //delegate: 'a', // the selector for gallery item
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        image: {
                            titleSrc:
                                function (item) {
                                    return item.el.find('img').attr('alt');
                                },
                        },
                    });

                });


                // Inline
                // var magPopInline = $('.magnificpopup--inline');
                // magPopInline.magnificPopup({
                //     type: 'inline',
                //     midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
                // });
                $(document).magnificPopup({
                    delegate: '.magnificpopup--inline',
                    type: 'inline',
                    midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
                });


                // Slick hdr--slider
                var hdrSldr = $('.hdr--slider .slider');
                var hdrSldrNnr = $('.hdr--slider .slider .container');
                var hdrSldrNv = $('.hdr--slider .slider__nav');
                var hdrSldrPrv = $('.hdr--slider .slider--prev');
                var hdrSldrNxt = $('.hdr--slider .slider--next');
                var hdrSldrScrll = $('.hdr--slider .slider__scroll');

                function doAnimations(elements) {
                    var animationEndEvents = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
                    elements.each(function () {
                        var $this = $(this);
                        var $animationDelay = $this.data('delay');
                        var $animationType = 'animate__animated animate__' + $this.data('animation');
                        $this.css({
                            'animation-delay': $animationDelay,
                            '-webkit-animation-delay': $animationDelay
                        });
                        $this.addClass($animationType).one(animationEndEvents, function () {
                            $this.removeClass($animationType);
                        });
                    });
                }

                hdrSldr.on('init', function (e, slick) {
                    var $firstAnimatingElements = $('.item.slick-active').find('[data-animation]');
                    doAnimations($firstAnimatingElements);
                });

                hdrSldr.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
                    var $animatingElements = $('.item[data-slick-index="' + nextSlide + '"]').find('[data-animation]');
                    doAnimations($animatingElements);
                });

                hdrSldr.slick({
                    arrows: true,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    prevArrow: hdrSldrPrv,
                    nextArrow: hdrSldrNxt,
                    dots: false,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });

                /*var time = 4;
                var isPause,
                tick,
                percentTime;*/
                var isPause;

                hdrSldrNnr.on({
                    mouseenter: function () {
                        isPause = true;
                        hdrSldr.slick('slickPause');
                    },
                    mouseleave: function () {
                        isPause = false;
                        hdrSldr.slick('slickPlay');
                    }
                });

                /*function startProgressbar() {
                    resetProgressbar();
                    percentTime = 0;
                    isPause = false;
                    tick = setInterval(interval, 10);
                }

                function interval() {
                    if(isPause === false) {

                        percentTime += 1 / (time+0.1);
                        hdrSldrBar.css({ width: percentTime+"%" });

                        if(percentTime >= 100) {
                            hdrSldr.slick('slickNext');
                            startProgressbar();

                        }
                    }
                }

                function resetProgressbar() {
                    hdrSldrBar.css({ width: 0+'%' });
                    clearTimeout(tick);
                }

                startProgressbar();

                hdrSldrPrv.click( function(){
                    startProgressbar();
                });
                hdrSldrNxt.click( function(){
                    startProgressbar();
                });*/

                // Slider Scroll
                hdrSldrScrll.click(function () {
                    $('html, body').animate({scrollTop: ($('#main .box').first().offset().top - 80)}, 500);
                });


                // Box Carousel
                // Slick box--carousel
                var crslSldr = $('.box--carousel .carousel');

                crslSldr.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                            breakpoint: 1080,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2,
                            }
                        },
                        {
                            breakpoint: 640,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        },
                    ],
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


                // Box Gallery Slider
                // Slick box--gallery--slider
                var gllrySldr = $('.box--gallery--slider .images');

                gllrySldr.slick({
                    arrows: false,
                    autoplay: true,
                    autoplaySpeed: 3000,
                    infinite: true,
                    pauseOnHover: true,
                    dots: true,
                    rows: 0, // remove div https://github.com/kenwheeler/slick/issues/3110
                });


                // Vertical Timeline - by CodyHouse.co
                function VerticalTimeline(element) {
                    this.element = element;
                    this.blocks = this.element.getElementsByClassName("cd-timeline__block");
                    this.images = this.element.getElementsByClassName("cd-timeline__img");
                    this.contents = this.element.getElementsByClassName("cd-timeline__content");
                    this.offset = 0.8;
                    this.hideBlocks();
                }

                VerticalTimeline.prototype.hideBlocks = function () {
                    if (!"classList" in document.documentElement) {
                        return; // no animation on older browsers
                    }
                    //hide timeline blocks which are outside the viewport
                    var self = this;
                    for (var i = 0; i < this.blocks.length; i++) {
                        (function (i) {
                            if (self.blocks[i].getBoundingClientRect().top > window.innerHeight * self.offset) {
                                self.images[i].classList.add("cd-timeline__img--hidden");
                                self.contents[i].classList.add("cd-timeline__content--hidden");
                            }
                        })(i);
                    }
                };

                VerticalTimeline.prototype.showBlocks = function () {
                    if (!"classList" in document.documentElement) {
                        return;
                    }
                    var self = this;
                    for (var i = 0; i < this.blocks.length; i++) {
                        (function (i) {
                            if (self.contents[i].classList.contains("cd-timeline__content--hidden") && self.blocks[i].getBoundingClientRect().top <= window.innerHeight * self.offset) {
                                // add bounce-in animation
                                self.images[i].classList.add("cd-timeline__img--bounce-in");
                                self.contents[i].classList.add("cd-timeline__content--bounce-in");
                                self.images[i].classList.remove("cd-timeline__img--hidden");
                                self.contents[i].classList.remove("cd-timeline__content--hidden");
                            }
                        })(i);
                    }
                };

                var verticalTimelines = document.getElementsByClassName("js-cd-timeline"),
                    verticalTimelinesArray = [],
                    scrolling = false;

                if (verticalTimelines.length > 0) {
                    for (var i = 0; i < verticalTimelines.length; i++) {
                        (function (i) {
                            verticalTimelinesArray.push(new VerticalTimeline(verticalTimelines[i]));
                        })(i);
                    }

                    //show timeline blocks on scrolling
                    window.addEventListener("scroll", function (event) {
                        if (!scrolling) {
                            scrolling = true;
                            (!window.requestAnimationFrame) ? setTimeout(checkTimelineScroll, 250) : window.requestAnimationFrame(checkTimelineScroll);
                        }
                    });
                }

                function checkTimelineScroll() {
                    verticalTimelinesArray.forEach(function (timeline) {
                        timeline.showBlocks();
                    });
                    scrolling = false;
                }


                // Accordion
                // Add inactive to accordion__toggle, add active to first
                // close all, open first item
                $('.accordion__toggle').toggleClass('inactive');
                //$('.accordion__toggle').first().toggleClass('active').toggleClass('inactive');

                // Add inactive to accordion__content, add active to first
                $('.accordion__content').addClass('inactive');
                //$('.accordion__content').first().removeClass('hide').addClass('selected');

                $('.accordion__toggle').click(function () {

                    /*if($(this).is('.inactive')) {
                        $(this).toggleClass('active').toggleClass('inactive');
                    } else {
                        $(this).toggleClass('active').toggleClass('inactive');
                    }*/
                    if ($(this).hasClass('inactive')) {
                        $(this).removeClass('inactive');
                        $(this).addClass('active');
                        $(this).next().removeClass('inactive');
                        $(this).next().addClass('active');
                    } else {
                        $(this).removeClass('active');
                        $(this).addClass('inactive');
                        $(this).next().removeClass('active');
                        $(this).next().addClass('inactive');
                    }

                    //Expand or collapse this panel
                    //$(this).next().slideToggle('fast');

                    //Hide the other panels
                    $('.accordion__toggle').not($(this)).removeClass('active').addClass('inactive');
                    $('.accordion__content').not($(this).next()).removeClass('active').addClass('inactive');

                });

                $('.mapclick').click(function () {

                    var clickID = $(this).data('mapclick');
                    //console.log(clickID);
                    var accordionToggle = $('#' + clickID + ' .accordion__toggle');

                    accordionToggle.removeClass('inactive');
                    accordionToggle.addClass('active');
                    accordionToggle.next().removeClass('inactive');
                    accordionToggle.next().addClass('active');

                    /*if( (accordionToggle).is('.inactive') ) {
                        accordionToggle.toggleClass('active').toggleClass('inactive');
                        //Expand or collapse this panel
                        accordionToggle.next().slideToggle('fast');
                    } else {
                        accordionToggle.toggleClass('active').toggleClass('inactive');
                    }*/

                    //Hide the other panels
                    $('.accordion__toggle').not(accordionToggle).removeClass('active').addClass('inactive');
                    $('.accordion__content').not(accordionToggle.next()).removeClass('active').addClass('inactive');

                });


                // Numbers

                // inViewport jQuery plugin
                // http://stackoverflow.com/a/26831113/383904
                // Based on Fiddle: https://jsfiddle.net/2v3mq3nh/4/
                // From this Answer: http://stackoverflow.com/a/36980280
                $(function ($, win) {
                    $.fn.inViewport = function (cb) {
                        return this.each(function (i, el) {
                            function visPx() {
                                var H = $(this).height(),
                                    r = el.getBoundingClientRect(), t = r.top, b = r.bottom;
                                return cb.call(el, Math.max(0, t > 0 ? H - t : (b < H ? b : H)));
                            }

                            visPx();
                            $(win).on("resize scroll", visPx);
                        });
                    };
                }(jQuery, window));

                jQuery(function ($) { // DOM ready and $ in scope

                    $(".box--numbers .value").inViewport(function (px) { // Make use of the `px` argument!!!
                        // if element entered V.port ( px>0 ) and
                        // if prop initNumAnim flag is not yet set
                        //  = Animate numbers
                        if (px > 0 && !this.initNumAnim) {
                            this.initNumAnim = true; // Set flag to true to prevent re-running the same animation
                            $(this).prop('Counter', 0).animate({
                                Counter: $(this).text()
                            }, {
                                duration: 2500,
                                step: function (now) {
                                    //$(this).text(parseFloat(now.toFixed(4)));
                                    $(this).text(Math.ceil(now));
                                }
                            });
                        }
                    });

                });


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        // Wordwide - it
        'page_id_10116': {
            init: function () {
                // JavaScript to be fired on the about us page

                // change form title
                var formTitle = 'Richiedi qui la "Guida all\'export"';
                $('.gform_title').text(formTitle);
            }
        },
        // About us page, note the change from about-us to about_us.
        // Wordwide - it
        'page_template_page_navigation': {
            init: function () {
                // JavaScript to be fired on the about us page

                // add current to menu
                $('.header__content').addClass('current-sub');
                $('li.current-page-ancestor').parents().addClass('current-menu-ancestor');
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
